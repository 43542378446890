
.business_bg {
    background-image: url('../../assets/illustrations/snapbrillia_business_hero_illustration.png'); 
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 115vh;
    background-color: linear-gradient(180deg, #F0F5FC 28.87%, rgba(255, 255, 255, 0) 100%);
  }

  @media screen and (max-width: 575.98px) {
    .business_bg {
      height: 60vh;
    }
  }