.sub-text {
    color: #545454;
}

.hidden-link {
    color: #6c757d;
    cursor: text;
}

.hidden-link:hover {
    color: #6c757d;
    cursor: text;
}