.home_bg {
  /* background-image: url('../src/assets/illustrations/snapbrillia_homepage_bg.png'); */
  background: linear-gradient(180deg, #F0F5FC 28.87%, rgba(255, 255, 255, 0) 100%);
}
  
.bg-light {
  background-color: #FFFFFF !important;
  box-shadow: 0px 0px 18px -4px rgba(104, 97, 97, 0.25);
  border-radius: 0px 0px 20px 20px;
}

.blockquote.home_quote {
  font-size: 1.55rem;
}

.blockquote-footer.home_quote {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.675em;
  color: #6c757d;
}

.typewriterhome {
  width: 5.15em;
  display: inline-block;
}

.marketer {
  animation: marketer;
}

.doer {
  animation: doer;
}

.marketer,  .doer {
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  position: relative;
  animation-duration: 20s;
  animation-timing-function: steps(25, end);
  animation-iteration-count: infinite;
}

.doer::after, .marketer::after {
  content: "|";
  position: absolute;
  color: black;
  right: 0;
  animation: caret infinite;
  animation-duration: 1s;
  animation-timing-function: steps(1, end);
  animation: blink 2s linear infinite;
} 
@keyframes marketer {
  0%, 50%, 100%{
      width: 0;
  }

  60%, 90% {
      width: 4.8em;
  }
}
@keyframes doer {
  0%, 50%, 100% {
      width: 0;
  }
  10%, 40% {
      width: 5.15em;
  }
}
@keyframes blink {
  0% {
   opacity: 0;
  }
  50% {
   opacity: 1;
  }
  100% {
   opacity: 0;
  }
}