.App {
  background-color: #F0F5FC;
  height: 100px;
}

.navbar-collapse.collapse.show {
  z-index: 5;
  background-color: #ffffff;
}

.home_bg {
  background: linear-gradient(180deg, #F0F5FC 28.87%, rgba(255, 255, 255, 0) 100%);
}

.bg-light {
  background-color: #FFFFFF !important;
  box-shadow: 0px 0px 18px -4px rgba(104, 97, 97, 0.25);
  border-radius: 0px 0px 20px 20px;
}

.primary-text-color {
  color: #2D2B6F;
}

.nav-link {
  color: #2D2B6F !important;
}

.active {
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

footer {
  background-color: #2D2B6F !important;
  color: #FFFFFF !important;
}

.navbar-light .navbar-toggler  {
  color: transparent;
  border-color: transparent;
}

.arrow-bounce {
  animation: bounce 0.5s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);
  animation-iteration-count: infinite;
  color: black;
}

@keyframes bounce {
  from {
      transform: translate3d(0, 0, 0);
  }
  to {
      transform: translate3d(0, 10px, 0);
  }
}
/* Prefix Support */

.arrow-bounce {
  -webkit-animation-name: bounce;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: cubic-bezier(
  .5, 0.05, 1, .5);
  -webkit-animation-iteration-count: infinite;
}

.button-bounce {
  -webkit-animation-name: bounce;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: cubic-bezier(
  .5, 0.05, 1, .5);
  -webkit-animation-iteration-count: infinite;
}

@-webkit-keyframes bounce {
  from {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
  }
  to {
      -webkit-transform: translate3d(0, 200px, 0);
      transform: translate3d(0, 200px, 0);
  }
}

.custom_fc_frame {
  right: 30px !important;
  bottom: 100px !important;
}

.text-small {
  font-size: 10px;
}

.logo {
  width: 250px;
}