.footer {
    background-color: #2D2B6F;
    color: #ffffff;
}

.footer-link {
    color: #ffffff;
    text-decoration: none;
}

.footer-link:hover {
    color: #ffffff;
    text-decoration: underline;
}

.copyright-text {
    font-size: 12px;
}

.footer.list-group-item {
    background-color: transparent;
}

.footer_social_icons {
    height: 26px;
    width: 26px;
}

.mb-n1 {
    margin-bottom: -0.8rem;
    z-index: -1;
}