.social_links {
    border: 1px solid #FFFDFD;
    /* Makuyev's Muted Murky Mass */
    box-shadow: 0px 0px 18px -4px rgba(104, 97, 97, 0.25);
    border-radius: 20px;
}

.social_icons {
    height: 50px;
    width: 50px;
}

a.social {
    text-decoration: none;
}