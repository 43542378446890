.aboutHero {
    margin-top: -15vh;
    background-image: url('../../assets/illustrations/snapbrillia_about_hero_updated.png');
    height: 160vh;
    background-repeat:no-repeat;
    background-size:contain;
    background-position:end;
}

.mt-n20 {
    margin-top: -10rem !important;
    margin-bottom: 10rem !important;
}

.lg-text {
    font-size: 3rem;
}

.list-group-item {
    border: none;
}

.figure-img.img-fluid {
    height: 80px;
    width: 80px;
}

.typewriter {
    width: 9em;
    display: inline-block;
}

.underrep {
    animation: underrep;
}

.selftaught {
    animation: selftaught;
}

.underrep, .selftaught {
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    position: relative;
    animation-duration: 20s;
    animation-timing-function: steps(25, end);
    animation-iteration-count: infinite;
}

.underrep::after, .selftaught::after {
    content: "|";
    position: absolute;
    color: black;
    right: 0;
    animation: caret infinite;
    animation-duration: 1s;
    animation-timing-function: steps(1, end);
    animation: blink 2s linear infinite;
} 

@keyframes selftaught {
    0%, 50%, 100% {
        width: 0;
    }

    60%, 90% {
        width: 5.5em;
    }
}
@keyframes underrep {
    0%, 50%, 100% {
        width: 0;
    }
    10%, 40% {
        width: 8.7em;
    }
}
@keyframes blink {
    0% {
     opacity: 0;
    }
    50% {
     opacity: 1;
    }
    100% {
     opacity: 0;
    }
}

@media screen and (min-width: 576px) {
    .lg-text {
        font-size: 1.75rem;
    }
    .aboutHero {
        margin-top: -5vh;
        background-image: url('../../assets/illustrations/snapbrillia_about_hero_updated.png');
        height: 40vh;
        background-repeat:no-repeat;
        background-size:contain;
        background-position:end;
    }
  }

  @media screen and (max-width: 767px) {
    .lg-text {
        font-size: 1.75rem !important;
    }
    .aboutHero {
        margin-top: -5vh;
        background-image: url('../../assets/illustrations/snapbrillia_about_hero_updated.png');
        height: 40vh !important;
        background-repeat:no-repeat;
        background-size:contain;
        background-position:end;
    }
  }

@media screen and (min-width: 768px) {
    .lg-text {
        font-size: 1.75rem !important;
    }
    .aboutHero {
        margin-top: -5vh;
        background-image: url('../../assets/illustrations/snapbrillia_about_hero_updated.png');
        height: 60vh !important;
        background-repeat:no-repeat;
        background-size:contain;
        background-position:end;
    }
  }

@media screen and (min-width: 992px) {
    .lg-text {
        font-size: 2rem !important;
    }
    .aboutHero {
        margin-top: -5vh;
        background-image: url('../../assets/illustrations/snapbrillia_about_hero_updated.png');
        height: 60vh !important;
        background-repeat:no-repeat;
        background-size:contain;
        background-position:end;
    }
  }

@media screen and (min-width: 1024px) {
    .lg-text {
        font-size: 2.1rem !important;
    }
    .aboutHero {
        margin-top: -5vh;
        background-image: url('../../assets/illustrations/snapbrillia_about_hero_updated.png');
        height: 80vh !important;
        background-repeat:no-repeat;
        background-size:contain;
        background-position:end;
    }
  }

@media screen and (min-width: 1400px) {
    .lg-text {
        font-size: 2.25rem !important;
    }
    .aboutHero {
        margin-top: -10vh;
        background-image: url('../../assets/illustrations/snapbrillia_about_hero_updated.png');
        height: 110vh !important;
        background-repeat:no-repeat;
        background-size:contain;
        background-position:end;
    }
  }

  @media screen and (min-width: 1600px) {
    .lg-text {
        font-size: 3rem !important;
    }
    .aboutHero {
        margin-top: -10vh;
        background-image: url('../../assets/illustrations/snapbrillia_about_hero_updated.png');
        height: 150vh !important;
        background-repeat:no-repeat;
        background-size:contain;
        background-position:end;
    }
  }

  @media screen and (min-width: 2500px) {
    .lg-text {
        font-size: 4.5rem !important;
    }
    .aboutHero {
        margin-top: -15vh;
        background-image: url('../../assets/illustrations/snapbrillia_about_hero_updated.png');
        height: 200vh !important;
        background-repeat:no-repeat;
        background-size:contain;
        background-position:end;
    }
  }